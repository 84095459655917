import { LoadableLazy } from '@confluence/loadable';

export { useShowBoldNewPagesSatisfactionSurvey } from '../src/unread-pages/useShowBoldNewPagesSatisfactionSurvey';
export { useBoldNewPagesSatisfactionSurveyActions } from '../src/unread-pages/BoldNewPagesSatisfactionSurveyStore';

export const BoldNewPagesSatisfactionSurvey = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-BoldNewPagesSatisfactionSurvey" */ '../src/unread-pages/BoldNewPagesSatisfactionSurvey'
			)
		).BoldNewPagesSatisfactionSurvey,
});
