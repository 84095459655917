export class PageMoveHistory {
	private size = 1;
	private stack: Array<{
		flagId?: string;
		undo(): void;
	}> = [];

	push({ undo }: { undo(): void }) {
		this.stack.push({ undo });
		this.stack = this.stack.slice(-1 * this.size);
	}

	pop = () => this.stack.pop();

	peek = () => this.stack.slice(-1)[0];

	reset = () => {
		this.stack = [];
	};
}
