import React, { type ReactNode } from 'react';

import { SpotlightTarget } from '@atlaskit/onboarding';

import type { ItemId, RenderItemParams, TreeItem } from '@confluence/tree';

import { PageTreeItem, type PageTreeItemProps } from './PageTreeItem';
import type { ContentTreeItem } from './data-transformers';

export function renderPageTreeItem(
	{ item, ...passThroughRenderItemParams }: RenderItemParams<ContentTreeItem>,
	additionalParams: {
		scrollToItem(item: TreeItem, el: any): void;
		onPageHighlighted(itemId: ItemId): void;
		onClick;
		onExpand: ((itemId: string) => void) | undefined;
		onCollapse: ((itemId: string) => void) | undefined;
		spaceId?: string | null;
		openQuickActionsId: string | null;
		focusedQuickActionsId: string | null;
		editingTitle: { contentId: string | null; isNewContent?: boolean };
		pageTreeWasSSRRendered?: boolean;
		getContentTreeSize: () => number;
		onNewContentRename?: (contentType: string) => void;
	} & Pick<
		PageTreeItemProps,
		| 'closeAllHoverPageCards'
		| 'draftText'
		| 'hideDraftHrefs'
		| 'isHoverPageCardOptedIn'
		| 'isInlineRenameEnabled'
		| 'isQuickActionsEnabled'
		| 'isSuperAdmin'
		| 'onBlurHoverTarget'
		| 'onEnterHoverTarget'
		| 'onLeaveHoverTarget'
		| 'renderResourcedEmoji'
		| 'setFocusedQuickActionsId'
		| 'setIsEditingTitleId'
		| 'setOpenQuickActionsId'
		| 'shouldRenderAfterIcon'
		| 'spaceHomePageId'
		| 'spaceKey'
		| 'updateSinglePage'
	>,
) {
	const {
		scrollToItem,
		onPageHighlighted,
		onClick,
		onExpand,
		onCollapse,
		spaceId,
		openQuickActionsId,
		focusedQuickActionsId,
		editingTitle,
		pageTreeWasSSRRendered,
		getContentTreeSize,
		...passThroughAdditionalParams
	} = additionalParams;

	const isDraft = item.data.status === 'DRAFT';
	const contentStatus = item.data.status;

	const scrollToRef = (() => {
		// skip scrollTo if page is SSR'd, SSR scrolling is handled by separate script
		if (!pageTreeWasSSRRendered) {
			return (el: HTMLDivElement) => {
				scrollToItem(item, el);
			};
		}
	})();

	const addSpotlight = (target: ReactNode) => (
		<SpotlightTarget name="page-tree-spotlight">{target}</SpotlightTarget>
	);

	const isQuickActionsOpen: boolean = openQuickActionsId === item.id;
	const isQuickActionsFocused: boolean = focusedQuickActionsId === item.id;
	const onHighlight = () => onPageHighlighted(item.id);
	const onClickWithItem: PageTreeItemProps['onClick'] = (event) => {
		onClick(event, item, onExpand, onCollapse, spaceId, getContentTreeSize);
	};

	const pageItem = (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className="item"
			data-testid="page-tree-item-wrapper"
			data-vc="page-tree-item-wrapper"
			ref={scrollToRef}
		>
			<PageTreeItem
				{...passThroughRenderItemParams}
				{...passThroughAdditionalParams}
				data-testid="page-tree-item"
				item={item}
				onHighlight={onHighlight}
				onClick={onClickWithItem}
				isQuickActionsOpen={isQuickActionsOpen}
				isQuickActionsFocused={isQuickActionsFocused}
				editingTitle={editingTitle}
				isDraft={isDraft}
				contentStatus={contentStatus}
			/>
		</div>
	);

	return item.data.isSelected ? addSpotlight(pageItem) : pageItem;
}
