import { createStore, createActionsHook, createStateHook } from 'react-sweet-state';
import type { Action } from 'react-sweet-state';

import {
	confluenceLocalStorageInstance as localStorage,
	keys as localStorageKeys,
	PERSISTED_KEYS_ON_SERVER as persistedLocalStorageKeys,
} from '@confluence/storage-manager';

const STORAGE_KEY_HAS_SEEN_SURVEY =
	persistedLocalStorageKeys.PERSISTED_BOLD_NEW_PAGES_SATISFACTION_SURVEY_HAS_SEEN;
const STORAGE_KEY_UNREAD_PAGES_VISITED = localStorageKeys.UNREAD_PAGES_VISITED;
const LOCAL_STORAGE_EXPIRATION = 60 * 60 * 24 * 365; // 365 days, in seconds

type BoldNewPagesSatisfactionSurveyState = {
	hasSeenSurvey: boolean;
	unreadPagesVisited: number;
};

const initialState: BoldNewPagesSatisfactionSurveyState = {
	hasSeenSurvey: localStorage?.getItemAsBoolean(STORAGE_KEY_HAS_SEEN_SURVEY) || false,
	unreadPagesVisited: parseInt(localStorage?.getItem(STORAGE_KEY_UNREAD_PAGES_VISITED), 10) || 0,
};

const actions = {
	markSurveySeen:
		(): Action<BoldNewPagesSatisfactionSurveyState> =>
		({ setState }) => {
			setState({ hasSeenSurvey: true });

			// Persist to local storage
			localStorage?.setItem(STORAGE_KEY_HAS_SEEN_SURVEY, true, LOCAL_STORAGE_EXPIRATION);
		},
	incrementUnreadPagesVisited:
		(): Action<BoldNewPagesSatisfactionSurveyState> =>
		({ setState, getState }) => {
			const state = getState();

			// If we've already shown the survey, don't bother incrementing any further
			if (state.hasSeenSurvey) {
				return;
			}

			// Increment the unread page state
			const unreadPagesVisited = state.unreadPagesVisited + 1;
			setState({ unreadPagesVisited });

			// Persist to local storage
			localStorage?.setItem(
				STORAGE_KEY_UNREAD_PAGES_VISITED,
				unreadPagesVisited,
				LOCAL_STORAGE_EXPIRATION,
			);
		},
};

export const boldNewPagesSatisfactionSurveyStore = createStore<
	BoldNewPagesSatisfactionSurveyState,
	typeof actions
>({
	initialState,
	actions,
	name: 'boldNewPagesSatisfactionSurveyStore',
});

export const useBoldNewPagesSatisfactionSurveyState = createStateHook(
	boldNewPagesSatisfactionSurveyStore,
);
export const useBoldNewPagesSatisfactionSurveyActions = createActionsHook(
	boldNewPagesSatisfactionSurveyStore,
);
