import { useBoldNewPagesSatisfactionSurveyState } from './BoldNewPagesSatisfactionSurveyStore';
import { useIsBoldPageTreeExperimentEligible } from './useIsBoldPageTreeExperimentEligible';

const UNREAD_PAGE_COUNT_SURVEY_TRIGGER = 3;

/**
 * Returns whether to show the satisfaction survey for the Bold New Pages experiment.
 *
 * Conditions for showing the survey:
 *   - User is in the experiment group
 *   - User has clicked on 3+ unread pages in the content tree
 *   - User hasn't already seen the survey
 */
export const useShowBoldNewPagesSatisfactionSurvey = (): any => {
	const isFeatureEnabled = useIsBoldPageTreeExperimentEligible();
	const { hasSeenSurvey, unreadPagesVisited } = useBoldNewPagesSatisfactionSurveyState();
	const hasVisitedEnoughUnreadPages = unreadPagesVisited >= UNREAD_PAGE_COUNT_SURVEY_TRIGGER;
	const shouldShowSurvey = isFeatureEnabled && !hasSeenSurvey && hasVisitedEnoughUnreadPages;

	return shouldShowSurvey;
};
